import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ProfileImage } from './ProfileImage';
import { Typo } from '../Typo/Typo';
import 'bootstrap/dist/css/bootstrap.min.css';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const Profile = ({ uid, name, imageUrl, sizeType }) => {
  return (
    <Link to={`/userInfo/${uid}`}>
      <Row>
        <ProfileImage url={imageUrl} sizeType={sizeType} />
        <Typo type={"bigTitle"} label={name} />
      </Row>
    </Link>
  );
};

export { Profile };

Profile.propTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  sizeType: PropTypes.string,
};

Profile.defaultProps = {
  uid: "1",
  name: "김모각코",
  imageUrl: "https://avatars.githubusercontent.com/u/48207131?v=4",
  sizeType: "small",
};
