const getMonthDuration = (date) => {
  const year = date.getFullYear();
  const month1 = date.getMonth() + 1;
  const month2 = date.getMonth() + 2;
  return {
    start: `${year}-${month1.toString().padStart(2, "0")}`,
    end: `${year}-${month2.toString().padStart(2, "0")}`,
  };
};

export { getMonthDuration };
