const UpdateMyMogaks = ({ uid, date, store }) => {
  store.get({
    collection: "mogak",
    where: [["leaderId", "==", uid]],
    limit: 10,
    storeAs: "myMogak",
  });
};

export { UpdateMyMogaks };
