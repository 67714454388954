import { Route, Routes } from "react-router-dom";
import Layout from "./components/MainLayout/MainLayout";
import Home from "./pages/Home/Home.js";
import Mogak from "./pages/Mogak/Mogak.js";
import CreateMogak from "./pages/CreateMogak/CreateMogak.js";
import UserPage from "./pages/UserPage/UserPage";
import UserInfo from "./pages/UserInfo/UserInfo";
import NotExist from "./pages/NotExist.js";
import SignIn from "./pages/SignIn/SignIn.js";
import { Test } from "./pages/Test/Test";

function App() {
  return (
    <div>
      <Routes>
        {/* <PrivateRoute path="/todos">
          <Todos />
        </PrivateRoute> */}
        <Route path="/signin" element={<SignIn />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/mogak/:id" element={<Mogak />} />
          <Route path="/create" element={<CreateMogak />} />
          <Route path="/user/:userId" element={<UserPage />} />
          <Route path="/userInfo" element={<UserInfo />} />
          <Route path="/test" element={<Test />} />
        </Route>
        <Route path="/*" element={<NotExist />} />
      </Routes>
    </div>
  );
}

export default App;
