const UpdateParticipantMogaks = ({ uid, date, store }) => {
  store.get({
    collection: "participant",
    where: [["uid", "==", uid]],
    limit: 10,
    storeAs: "myParticipantMogak",
  });
};

export { UpdateParticipantMogaks };
