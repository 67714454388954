import PropTypes from 'prop-types';
import { Container, Contents } from "../MainLayout/Container";

export const Footer = ({ backgroundColor }) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Contents>
        <div>
          로고
        </div>
        <div>
          개인정보처리방침
        </div>
      </Contents>
    </Container>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
};

Footer.defaultProps = {
  backgroundColor: "#b4b4b4",
};
