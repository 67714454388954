import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

const BigTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Description = styled.div`
  color: #999;
  font-size: 1rem;
  font-weight: 200;
`;

const Typo = ({ type, label }) => {
  if (type === 'bigTitle') {
    return <BigTitle>{label}</BigTitle>;
  }
  if (type === 'description') {
    return <Description>{label}</Description>;
  }
  return <div>{label}</div>;
};

export { Typo };

Typo.propTypes = {
  type: PropTypes.oneOf(['bigTitle', 'normal', 'description']),
  label: PropTypes.string,
};

Typo.defaultProps = {
  type: 'description',
  label: '',
};
