// import { useFirestore } from "react-redux-firebase";

import { getMonthDuration } from "./getMonthDuration";

const UpdateMonthlyMogaks = ({ date, store }) => {
  const { start, end } = getMonthDuration(date);
  store.get({
    collection: "mogak",
    where: [
      ["datetime", ">=", start],
      ["datetime", "<", end],
    ],
  });
};

export { UpdateMonthlyMogaks };
