const MogakItemKo = {
  "title": "모임명",
  "titleRequired": "모임명은 필수 입력입니다.",
  "titlePlaceholder": "모임명을 적어주세요(20자)",
  "titleErrorMsg": "모임명은 20자 이내로 입력해주세요.",

  "contents": "모각 내용",
  "contentsRequired": "모각 내용는 필수 입력입니다.",
  "contentsPlaceholder": "모임에 대한 전반적인 내용을 적어주세요.(300자)",
  "contentsErrorMsg": "모각 내용는 300자 이내로 입력해주세요.",

  "datetime": "모임 날짜/시간",
  "datetimeRequired": "모임 날짜/시간은 필수 입력입니다.",

  "locationType": "모임형태",
  "locationTypeRequired": "모임형태는 필수 입력입니다.",
  "offline": "오프라인",
  "online": "온라인",

  "offlineLocation": "위치",
  "lo1": "시/도",
  "lo1Required": "시/도는 필수 입력입니다.",
  "lo2": "시/군/구",
  "lo2Required": "시/군/구는 필수 입력입니다.",

  "locationDetail": "상세주소",
  "locationDetailRequired": "상세주소는 필수 입력입니다.",
  "locationDetailPlaceholder": "상세주소를 적어주세요(100자)",
  "locationDetailOfflineDescription": "예) 1층 101호 / 지도링크 등",
  "locationDetailOnlineDescription": "예) Zoom, Discode 링크 등",
  "locationDetailPolicy": "* 참여등록을 하면 상세주소를 볼 수 있습니다",

  "participantType": "참가자 참여 방법",
  "participantTypeRequired": "참여방식은 필수 입력입니다.",
  "free": "인원 수 제한없이 자유롭게 아무나 참여할 수 있습니다",
  "first": "선착순으로 참여할 수 있습니다",
  "contact": "참가 등록을 먼저 받고 별도 연락을 통해 선발합니다",
  "participantOpenPolicy": "* 기타 자세한 정보는 참여 등록을 하면 상세정보를 볼 수 있습니다",

  "contactType": "연락방식",
  "contactTypeRequired": "연락방법은 필수 입력입니다.",
  "none": "없음",
  "email": "이메일",
  "phone": "전화번호",
  "twitter": "트위터",
  "kakaoId": "카카오 아이디",
  "kakaoOpen": "카카오 오픈채팅",
  "other": "기타",

  "contactDetail": "연락처",
  "contactRequired": "연락처는 필수 입력입니다.",
  "contactPlaceholder": "연락처를 적어주세요(100자)",
  "contactDetailDescription": "예)",
  "contactDetailPolicy": "* 참여등록시 연락처를 볼 수 있습니다",

  "participantCount": "최대 인원",
  "participantCountRequired": "참여인원은 필수 입력입니다.",
  "participantCountPlaceholder": "최대 몇명까지 참여할 수 있나요?",
  "participantCountDescription": "예) 10명 / 10명 이상 / 10명 이하",

  "shareType": "공개범위",
  "shareTypeRequired": "공개범위는 필수 입력입니다.",
  "all": "검색이 가능한 전체공개 일정",
  "link": "공유 링크로만 확인할 수 있는 일정",

  "participantList": "참가 등록자 목록",
  "apply": "등록하기",
  "cancel": "취소",

  "leader": "모임장",

}

export { MogakItemKo };