import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import { Container, Contents } from "../../components/MainLayout/Container";

const UserInfo = () => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.firebase.auth);
  const [user, setUser] = useState({});
  const [mogakName, setMogakName] = useState(user.displayName);
  const [newMogakName, setNewMogakName] = useState(user.displayName);
  const [modifyMogakName, setModifyMogakName] = useState(false);

  if (auth.isEmpty) {
    navigate("/");
  }

  const updateDisplayName = () => {
    const name = newMogakName.trim();
    if (name.length < 2) {
      alert("닉네임은 두글자 이상 입력해주세요.");
      return;
    }

    if (/\s/g.test(name)) {
      alert("닉네임 중간에 공백을 제거한 후 입력해주세요.");
      return;
    }

    firestore
      .collection("userInfo")
      .where("displayName", "==", name)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          firestore
            .collection("userInfo")
            .doc(auth.uid)
            .update({
              displayName: name,
            })
            .then(() => {
              console.log("Document successfully updated!");
              setMogakName(name);
              setModifyMogakName(false);
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        } else {
          alert("이미 존재하는 이름입니다.");
          return;
        }
      });
  };

  useEffect(() => {
    firestore
      .collection("userInfo")
      .doc(auth.uid)
      .get()
      .then((user) => {
        if (user.exists) {
          console.log("Document data:", user.data());
          setUser(user.data());
          setMogakName(user.data().displayName);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such user!");
        }
      });
  }, [auth.uid, firestore]);

  useEffect(() => {
    setMogakName(user.displayName);
    setNewMogakName(user.displayName);
  }, [user]);

  return (
    <Container>
      <Contents>
        프로필 이미지: <img src={user.avatarUrl} alt="User Profile" />
        <br />
        {modifyMogakName ? (
          <>
            <input
              type="text"
              value={newMogakName}
              maxLength="30"
              onChange={(event) => {
                setNewMogakName(event.target.value);
              }}
            />
            <button
              onClick={() => {
                updateDisplayName();
              }}
            >
              입력완료
            </button>
            <button
              onClick={() => {
                setModifyMogakName(false);
              }}
            >
              취소
            </button>
          </>
        ) : (
          <>
            <h3>
              모각에서 사용할 닉네임: {mogakName}
              <button onClick={() => setModifyMogakName(true)}>변경하기</button>
            </h3>
          </>
        )}
        <br />
        <h3>생성일자: {user.createdAt}</h3>
      </Contents>
    </Container>
  );
};
export default UserInfo;
