import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: 200;
`;

const ItemLabel = styled.label`
  font-size: 1rem;
  font-weight: 200;
  padding: 0rem 0.5rem;
`;

const Span = styled.span`
  color: #f00;
  font-weight: 500;
  font-size: 1.2rem;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormRadio = React.forwardRef(
  ({ name, options, label, required, type, errors, ...props }, ref) => {
    return (
      <div>
        <Form.Label>
          {label}
          <Span>{required && '*'}</Span>
        </Form.Label>
        <Col>
          {options.map((item) => {
            const { value, label } = item;
            return (
              <Row>
                <Form.Check
                  {...props}
                  name={name}
                  ref={ref}
                  type={type}
                  value={value}
                  id={`field-${value}`}
                // className={['field', `field--${type}`].join(' ')}
                />
                <ItemLabel htmlFor={`field-${value}`}>
                  {label}
                </ItemLabel>
              </Row>
            )
          })}
        </Col>
        {errors && errors.hasOwnProperty(name) && <Span>{errors[name].message}</Span>}
      </div>
    );
  }
);

export { FormRadio };

FormRadio.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

FormRadio.defaultProps = {
  name: 'text',
  label: 'Label',
  options: [],
};
