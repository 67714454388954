import styled from 'styled-components';

const StyledProfileImage = styled.img`
  border-radius: 50%;
  width: ${props => props.size || 10};
  height: ${props => props.size || 10};
`;

const ProfileImage = ({ url, sizeType, customSize }) => {
  let size = "0px";
  switch (sizeType) {
    case 'small':
      size = "24px";
      break;
    case 'medium':
      size = "40px";
      break;
    case 'large':
      size = "150px";
      break;
    default:
      size = customSize + "px";
  }

  return <StyledProfileImage src={url} size={size} alt="User Profile" />
};

export { ProfileImage };
