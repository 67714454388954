import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: 200;
`;

const Span = styled.span`
  color: #f00;
  font-weight: 500;
  font-size: 1.2rem;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FormSelect = React.forwardRef(
  ({ name, options, label, required, errors, ...props }, ref) => {
    /**
     * @param {string} name
     * @param {array} options
     * @param {string} label
     * @param {boolean} required
     * @param {object} errors
     * @param {object} props
     * 
     * @returns {JSX.Element}
     * 
     * @example
     * Options: [{value: '서울', label: '서울'}, {value: '부산', label: '부산'}]
     * 
     */
    return (
      <div key={name}>
        {label && (
          <Form.Label>
            {label}
            <Span>{required && '*'}</Span>
          </Form.Label>
        )}
        <Row>
          <Form.Select {...props} ref={ref} name={name}>
            {options.map((item) => {
              const { value, label } = item;
              return (
                <option
                  key={value}
                  value={value}
                >
                  {label}
                </option>
              )
            })}
          </Form.Select>
        </Row>
        {errors && errors.hasOwnProperty(name) && <Span>{errors[name].message}</Span>}
      </div>
    );
  }
);

export { FormSelect };

FormSelect.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

FormSelect.defaultProps = {
  name: 'text',
  label: 'Label',
};
