import { format, addMinutes } from "date-fns";

const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];

const getDayTime = (datetime) => {
  /** 
   * datetime: 2022-10-25T12:46:25+09:00
   */
  const dt = new Date(datetime);
  const date = format(addMinutes(dt, 0), "MM월 dd일 ") + WEEKDAY[dt.getDay()] + "요일";
  const time = format(addMinutes(dt, 0), 'HH:mm');
  return { date, time };
}

export { getDayTime, WEEKDAY };