import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatISO, addHours } from 'date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: 200;
`;

const Span = styled.span`
  color: #f00;
  font-weight: 500;
  font-size: 1.2rem;
`;

const GetDateMin = () => {
  /**
   * FormField 에서 type="datetime-local" 을 사용할 때
   * 오늘 날짜 이전은 선택할 수 없도록
   * min 속성을 추가해야 할 때 넣을 값을 생성하는 함수
   */
  const now = new Date();
  const nowPlusOneHour = addHours(now, 9);
  // const result = formatISO(nowPlusOneHour).substring(0, 16)
  const result = formatISO(now).substring(0, 16)
  return result;
}

const FormField = React.forwardRef(
  ({ type, name, label, required, placeholder, errors, description, ...props }, ref) => {
    return (
      <div>
        <Form.Label htmlFor={name}>
          {label}
          <Span>{required && '*'}</Span>
        </Form.Label>

        <Form.Control
          {...props}
          name={name}
          ref={ref}
          type={type}
          id={name}
          // className={['field', `field--${type}`].join(' ')}
          placeholder={placeholder}
          min={type === 'datetime-local' ? GetDateMin() : type === 'number' ? 0 : ''}
          max={type === 'number' ? 10000 : ''}
          as={type === 'textarea' ? 'textarea' : undefined}
          rows={type === 'textarea' ? 10 : undefined}
          aria-describedby={`${name}_description`}
        />
        <Form.Text id={`${name}_description`} muted>
          {description}
        </Form.Text>
        {errors && errors.hasOwnProperty(name) && <Span>{errors[name].message}</Span>}
      </div>
    );
  }
);

export { FormField };

FormField.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'password', 'file', 'number', 'datetime-local', 'textarea']),
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  description: PropTypes.string,
};

FormField.defaultProps = {
  type: 'text',
  name: 'text',
  label: 'Label',
  placeholder: '',
  description: '',
};
