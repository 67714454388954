import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor || "#fff"};
`;

const Contents = styled.div`
  width: 1024px;

  @media screen and (max-width: 1023px) {
    /* 타블렛 기준*/
    width: 768px;
  }

  @media screen and (max-width: 767px) {
    /* 모바일 기준 */
    width: 90%;
    padding: 0px 16px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export { Container, Contents, Row };