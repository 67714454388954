import styled from 'styled-components';
import PropTypes from 'prop-types';

const getDday = (leftDay) => {
  if (leftDay == 0) {
    return `D-Day`;
  } else if (leftDay < 0) {
    return `D+${-1 * leftDay} `;
  }
  return `D-${leftDay} `
}

const DDayContainer = styled.div`
  display: inline-block;
  font-size: 1rem;
  color: white;
  border-radius: 10%;
  padding: 2px 4px; 
  background-color: ${prop => (prop.dday == 0) ? "green" : ((prop.dday > 0) ? "red" : "gray")};
`;

const DDay = ({ size, datetime }) => {
  const dt = new Date(datetime) - new Date();
  const diffDay = Math.floor(dt / (1000 * 60 * 60 * 24));
  const leftDay = diffDay + 1;
  return (
    <DDayContainer dday={leftDay}>
      {getDday(leftDay)}
    </DDayContainer>
  );
};

export { DDay };

DDay.propTypes = {
  size: PropTypes.string,
  datetime: PropTypes.string,
};

DDay.defaultProps = {
  size: undefined,
  datetime: undefined,
};
