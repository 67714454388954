import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { useSelector } from "react-redux";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { Container } from "../MainLayout/Container";

const Layout = () => {
  const auth = useSelector((state) => state.firebase.auth);
  const firebase = useFirebase();

  return (
    <Container>
      <Header auth={auth.isEmpty ? null : auth} logout={firebase.logout} />
      <Outlet />
      <Footer backgroundColor={"#b4b4b4"} />
    </Container>
  );
};

export default Layout;
