import React from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import { formatISO } from "date-fns";

const SignIn = () => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    firebase
      .login({
        provider: "google",
        type: "popup",
      })
      .then((res) => {
        // console.log("signInWithGoogle", res, auth, uid);
        const auth = firebase.auth();
        const currentUser = auth.currentUser;
        if (res.additionalUserInfo.isNewUser) {
          firestore
            .collection("userInfo")
            .doc(currentUser.uid)
            .set({
              uid: currentUser.uid,
              displayName: currentUser.displayName,
              avatarUrl: currentUser.photoURL,
              createdAt: formatISO(new Date()),
            })
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        }
        navigate("/userInfo");
      });
  };

  return (
    <div>
      <h1>Sign In</h1>
      <button
        onClick={(event) => {
          event.preventDefault();
          signInWithGoogle();
        }}
      >
        Sign In with Google
      </button>
    </div>
  );
};
export default SignIn;
