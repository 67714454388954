import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty, useFirestore } from "react-redux-firebase";
import { UpdateMonthlyMogaks } from "../../apis/updateMonthlyMogaks";
import { Container, Contents } from "../../components/MainLayout/Container";

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
`;

const Card = styled.div`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 11rem;
  background: orange;
  color: white;
  border: 2px solid black;
`;

const Home = () => {
  const [month, setMonth] = useState(new Date());
  const store = useFirestore();

  // useFirestoreConnect({
  //   collection: `/mogak`,
  //   where: [
  //     ["datetime", ">=", start],
  //     ["datetime", "<", end],
  //   ],
  //   storeAs: "mogak",
  // });

  useEffect(() => {
    UpdateMonthlyMogaks({ date: month, store: store });
  }, [month]);

  const mogaks = useSelector((state) => state.firestore.data.mogak);
  // const mogaks = useSelector((state) => state.firestore.ordered.mogak);

  // console.log("m1", mogaks);
  // console.log("m2", mogakss);
  return (
    <Container>
      <Contents>

        <h1>{month.getMonth() + 1}월 모각코</h1>
        <input
          type="button"
          value="이전월"
          onClick={() => setMonth(new Date(month.setMonth(month.getMonth() - 1)))}
        />
        <input
          type="button"
          value="다음월"
          onClick={() => setMonth(new Date(month.setMonth(month.getMonth() + 1)))}
        />
        <table>
          <thead>
            <tr>
              <th>일</th>
              <th>월</th>
              <th>화</th>
              <th>수</th>
              <th>목</th>
              <th>금</th>
              <th>토</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
              <td>
                7<div>햄스터의...</div>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>12</td>
              <td>13</td>
              <td>14</td>
            </tr>
          </tbody>
        </table>
        <List>
          {mogaks ? (
            Object.values(mogaks).map(
              (mogak) =>
                mogak && (
                  <Card
                    key={mogak.id}
                  // onClick={() => {
                  // navigate(`/mogak/${mogak.id}`);
                  // }}
                  >
                    <Link
                      to={`/mogak/${mogak.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>모임명: {mogak.title}</div>
                      <div>모임장: {mogak.leader}</div>
                    </Link>
                  </Card>
                )
            )
          ) : (
            <div>모각 일정이 없습니다.</div>
          )}
        </List>
        <li>
          <Link to="/bad">존재하지 않는 페이지</Link>
        </li>
      </Contents>
    </Container>
  );
};

export default Home;
