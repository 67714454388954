const NotExist = (location) => {
  return (
    <div>
      <h2>404 이 페이지는 존재하지 않습니다:</h2>
      <p>{location.pathname}</p>
    </div>
  );
};

export default NotExist;
