import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { formatISO, isBefore } from "date-fns";
import { Container, Contents } from "../../components/MainLayout/Container";

const Mogak = () => {
  const { uid, displayName } = useSelector((state) => state.firebase.auth);
  let { id } = useParams();
  const firestore = useFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    firestore.get({
      collection: "mogak",
      where: [["id", "==", id]],
    });
    // .then((snapshot) => {
    //   snapshot.docs.forEach((doc) => {
    //     console.log(doc.data());
    //     firestore.get({
    //       collection: "participant",
    //       where: [["mogakId", "==", id]],
    //     });
    //   });
    // });
    firestore.get({
      collection: "participant",
      where: [["mogakId", "==", id]],
    });
    /* not working*/
    // firestore.get([
    //   {
    //     collection: "mogak",
    //     where: [["id", "==", id]],
    //   },
    //   {
    //     collection: "participant",
    //     where: [["mogakId", "==", id]],
    //   },
    // ]);
  }, []);

  const mogak = useSelector((state) => state.firestore.data.mogak) ?? {};
  const mogakItem = mogak[id];
  const participants = Object.values(
    useSelector((state) => state.firestore.data.participant) ?? []
  );
  const timeLimit = new Date(new Date(mogakItem?.datetime).toDateString());
  const nowDate = new Date(new Date().toDateString());
  const isOutdated = isBefore(timeLimit, nowDate);
  const isParticipant =
    (mogakItem && mogakItem.leaderId === uid) ||
    (participants[0] &&
      participants.filter((participant) => participant?.uid === uid).length >
      0);
  const contact = useSelector((state) => state.firestore.data.contact) ?? {};

  useEffect(() => {
    if (isParticipant) {
      firestore.get({
        collection: "contact",
        where: [["mogakId", "==", id]],
      });
    }
  }, [isParticipant]);

  // console.log(
  //   "readered",
  //   "mogak",
  //   mogak,
  //   "participants",
  //   participants,
  //   "isParticipant",
  //   isParticipant,
  //   "contact",
  //   contact
  // );
  if (!mogak) {
    return <div>로딩중</div>;
  }
  const updateViewCount = () => {
    // console.log("updateViewCount", mogakItem);
    if (mogakItem) {
      firestore
        .collection("mogak")
        .doc(id)
        .update({
          views: mogakItem.views + 1,
        });
    }
  };
  updateViewCount();

  const deleteMogak = () => {
    const result = window.confirm("정말 삭제하시겠습니까?");
    if (result) {
      const batch = firestore.batch();
      const rmMogak = firestore.collection("mogak").doc(mogakItem.id);
      batch.delete(rmMogak);
      const rmContactId = Object.keys(contact)[0];
      const rmContact = firestore.collection("contact").doc(rmContactId);
      batch.delete(rmContact);

      const r = async () => await batch.commit();
      r().then(() => {
        navigate("/");
      });
      // firestore.delete(`/mogak/${id}`);
      // navigate("/");
    }
  };

  const cancelMogak = () => {
    const result = window.confirm(
      "* 참여자가 있는 모각은 삭제할 수 없고 취소할 수 있습니다\n참여자들이 신청을 모두 취소하면 삭제할 수 있습니다.\n정말 취소하시겠습니까?\n"
    );
    if (result) {
      firestore
        .collection("mogak")
        .doc(id)
        .update({
          isCanceled: true,
        })
        .then(() => {
          window.location.reload();
        });
    }
  };

  const applyMogak = () => {
    const result = window.confirm("이 일정에 참가신청을 하시겠습니까?");
    if (result) {
      const now = new Date();
      const newParticipant = {
        mogakId: `${id}`,
        uid: uid,
        name: displayName,
        createdAt: formatISO(now),
      };

      firestore
        .collection("participant")
        .add(newParticipant)
        .then((docRef) => {
          docRef.update({
            id: docRef.id,
          });
          window.location.reload();
        });
    }
  };

  const cancleApply = (pid) => {
    const result = window.confirm("참가신청을 취소 하시겠습니까?");
    if (result) {
      firestore.delete(`/participant/${pid}`).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Container>
      <Contents>
        {mogakItem && (
          <>
            <div>
              <h1>{mogakItem.title}</h1>
              {mogakItem.leaderId === uid &&
                (participants.length === 0 ? (
                  <input
                    type="button"
                    value={"삭제하기"}
                    onClick={() => deleteMogak()}
                  />
                ) : (
                  <input
                    type="button"
                    value={"모임취소"}
                    onClick={() => cancelMogak()}
                  />
                ))}
            </div>
            <div>
              {mogakItem.isCanceled && <h1>이 모각은 취소되었습니다</h1>}
              {isOutdated && <h1>이 모각은 종료되었습니다</h1>}
              {mogakItem.createdAt && <h3>생성시간: {mogakItem.createdAt}</h3>}
              {mogakItem.datetime && <h3>모임날짜: {mogakItem.datetime}</h3>}
              {mogakItem.views && <h3>조회수: {mogakItem.views}</h3>}
              {mogakItem.duration && <h3>진행시간: {mogakItem.duration}</h3>}
              {mogakItem.contents && <h3>내용: {mogakItem.contents}</h3>}
              {mogakItem.locationType && (
                <h3>
                  모임방법:{" "}
                  {mogakItem.locationType === "lo-offline"
                    ? "오프라인"
                    : "온라인"}
                </h3>
              )}
              {mogakItem.lo1 && (
                <h3>
                  위치: {mogakItem.lo1} {mogakItem.lo2 ? mogakItem.lo2 : "어딘가"}
                </h3>
              )}
              {mogakItem.locationDetail && (
                <h3>위치세부: {mogakItem.locationDetail}</h3>
              )}
              {mogakItem.vibeType && <h3>분위기: {mogakItem.vibeType}</h3>}
              {mogakItem.vibeDetail && (
                <h3>분위기(기타): {mogakItem.vibeDetail}</h3>
              )}
              {mogakItem.applyType && <h3>지원타입: {mogakItem.applyType}</h3>}
              {mogakItem.peopleLimit && (
                <h3>제한인원: {mogakItem.peopleLimit}</h3>
              )}
              {mogakItem.feeType && <h3>비용여부: {mogakItem.feeType}</h3>}
              {mogakItem.feeAmount && <h3>회비: {mogakItem.feeAmount}</h3>}
              {mogakItem.shareType && <h3>공유타입: {mogakItem.shareType}</h3>}
              {/* {mogakItem.updatedAt && <h3>수정시간: {mogakItem.updatedAt}</h3>} */}
            </div>
            <div>
              <h3>
                주최자:{" "}
                <Link to={`/user/${mogakItem.leaderId}`}>
                  {" "}
                  {mogakItem.leader}
                </Link>
              </h3>
            </div>
            <div>
              {mogakItem.contact && <h3>연락방법: {mogakItem.contact}</h3>}
              {mogakItem.contact && (
                <>
                  <h3>
                    연락처:
                    {isParticipant && Object.values(contact)[0]?.detail}
                    <br />
                  </h3>
                  (참가자만 연락처를 볼 수 있습니다)
                </>
              )}
              <h3>참가자 정보: {participants.length + 1}</h3>
              <div key={mogakItem.leaderId}>{mogakItem.leader}</div>
              {participants.map(
                (participant) =>
                  participant && (
                    <div key={participant.id}>
                      <Link to={`/user/${participant.uid}`}>
                        {participant.name}
                      </Link>
                      {participant.uid === uid && (
                        <input
                          type="button"
                          value={"취소"}
                          onClick={() => cancleApply(participant.id)}
                        />
                      )}
                    </div>
                  )
              )}
            </div>
            {!isOutdated &&
              uid !== mogakItem.leaderId &&
              !isParticipant &&
              !mogakItem.isCanceled && (
                <div>
                  <input
                    type="button"
                    value={"참가신청 하기"}
                    onClick={() => applyMogak()}
                  />
                </div>
              )}
          </>
        )}
      </Contents>
    </Container>
  );
};

export default Mogak;
