import styled from "styled-components";
import PropTypes from 'prop-types';
import { MogakCard } from "../Card";
import { Container } from "../MainLayout/Container";

const Title = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  width: 100%;
  font-size: 1.5rem;
  margin-bottom: 1rem;
    
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
`;

const ListBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  align-items: center;
  justify-items: space-between;
  width: 100%;
  flex-wrap: wrap;
  
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 10px;
  }
`;

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: gray;
`;

const UserPageMogakList = ({
  title,
  mogakList,
  itemBackgroundColor,
  backgroundColor,
}) => {
  return (
    <Container
      backgroundColor={backgroundColor}
    >
      <Title>
        {title}
      </Title>
      {mogakList && mogakList.length > 0 ? (
        <ListBox>{
          Object.values(mogakList).map((item) => (
            <MogakCard key={item.id}
              id={item.id}
              title={item.title}
              contents={item.contents}
              datetime={item.datetime}
              locationType={item.locationType}
              views={item.views}
              backgroundColor={itemBackgroundColor}
              userImg={item.userImg}
              userName={item.userName}
              isMine={item.isMine}
            />
          ))
        }
        </ListBox>
      ) : (
        <EmptyList>항목이 비었습니다</EmptyList>
      )}
    </Container>
  );
};

export { UserPageMogakList };

UserPageMogakList.propTypes = {
  title: PropTypes.string,
  mogakList: PropTypes.array,
  itemBackgroundColor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['white', '#b4b4b4']),
};

UserPageMogakList.defaultProps = {
  title: null,
  mogakList: null,
  itemBackgroundColor: null,
  backgroundColor: null,
};
