import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typo } from "../Typo/Typo";

const CardContainer = styled.div`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 15rem;
  background: gray;
  color: white;
  border: 2px solid black;
`;

const ParticipantCard = ({ participant }) => {
  return (
    <CardContainer key={participant.id}>
      <Link
        to={`/mogak/${participant.mogakId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {participant.createdAt && (
          <Typo
            label={`
          신청날짜: ${participant.createdAt}
        `}
          />
        )}
      </Link>
    </CardContainer>
  );
};

export { ParticipantCard };
