import styled from 'styled-components';
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { UpdateMyMogaks } from "../../apis/updateMyMogaks";
import { UpdateParticipantMogaks } from "../../apis/updateMyParticipantMogaks";
import { getUser } from "../../apis/getUserMogak";
import { ParticipantCard, MogakCard } from "../../components/Card";
import { UserPageMogakList } from "../../components/UserPageMogakList/UserPageMogakList";
import { Container, Contents } from "../../components/MainLayout/Container";

// import { useFirestoreConnect } from "react-redux-firebase";


const UserPageLayout = () => {
  const now = new Date();
  const [month, setMonth] = useState(now);
  const [user, setUser] = useState({});
  const store = useFirestore();
  let { userId } = useParams();

  useEffect(() => {
    UpdateMyMogaks({ uid: userId, date: month, store: store });
    UpdateParticipantMogaks({ uid: userId, date: month, store: store });
    // getUser({ store: store, userId: userId });
    getUser({ store: store, userId: userId }).then((user) => {
      if (user.exists) {
        console.log("Document data:", user.data());
        setUser(user.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such user!");
      }
    });
  }, [month, store, userId]);

  const mogaks = Object.values(
    useSelector((state) => state.firestore.data.myMogak) ?? {}
  );
  const userMogak = mogaks.filter((mogak) => mogak?.leaderId === userId);
  const waitMogaks = userMogak?.filter(
    (mogak) => new Date(mogak?.datetime) > now
  );
  const oldMogaks = userMogak?.filter(
    (mogak) => new Date(mogak?.datetime) <= now
  );
  const participant = Object.values(
    useSelector((state) => state.firestore.data.myParticipantMogak) ?? {}
  );

  return (
    <Container>
      <Contents>
        <input
          type="button"
          value="이전월"
          onClick={() => setMonth(new Date(month.setMonth(month.getMonth() - 1)))}
        />
        {month.getMonth() + 1}월
        <input
          type="button"
          value="다음월"
          onClick={() => setMonth(new Date(month.setMonth(month.getMonth() + 1)))}
        />
        <h2>{user.displayName} 님이 만든 모각</h2>
        <UserPageMogakList title="예정된 모각코" mogakList={waitMogaks} backgroundColor={"white"} />
        <UserPageMogakList title="지난 모각코" mogakList={oldMogaks} />

        <h2>{user.displayName} 님의 참여한 모각</h2>
        <h3>진행할 모각코</h3>
        {participant ? (
          participant.map((item) => (
            <ParticipantCard key={item.id} participant={item} />
          ))
        ) : (
          <div>진행할 참여 모각이 없습니다.</div>
        )}
        <h3>지난 모각코</h3>
      </Contents>
    </Container>
  );
};

export default UserPageLayout;

UserPageLayout.propTypes = {
};

UserPageLayout.defaultProps = {
};