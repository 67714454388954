import { Container, Contents } from "../../components/MainLayout/Container";
import { CreateForm } from "../../components/CreateForm/CreateForm";

const Test = ({ }) => {
  return (
    <Container>
      <Contents>
        <CreateForm />
      </Contents>
    </Container>
  )
};

export { Test };
