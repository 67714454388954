import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useForm } from "react-hook-form";
import { formatISO } from "date-fns";
import { location1, location2 } from "../../apis/koreaAddress";
import { Container, Contents } from "../../components/MainLayout/Container";


const MyButton = styled.input`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 11rem;
  background: red;
  color: white;
  border: 2px solid black;
`;

const ErrorMsg = styled.div`
  color: red;
`;

const CreateMogak = () => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const { displayName, uid } = useSelector((state) => state.firebase.auth);

  const getnow = () => {
    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
    let dateOffset = new Date(now.getTime() - offset);
    return dateOffset.toISOString();
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      locationType: "lo-range",
      contact: "kakao-open",
      datetime: getnow().slice(0, 16),
      lo1: "",
      lo2: "",
      // shareType: "public",
      // applyType: "free",
      // feeType: "self",
    },
  });

  const locationType = watch("locationType");
  const lo1 = watch("lo1");
  const contact = watch("contact");
  // const applyType = watch("applyType");
  // const vibeType = watch("vibeType");
  // const feeType = watch("feeType");

  const onSubmit = (data) => {
    // console.log(data);
    const result = window.confirm(
      "모임을 생성하시겠습니까?\n모임을 생성하면 수정할 수 없습니다."
    );
    if (!result) {
      return;
    }
    const now = new Date();
    const newMogak = {
      ...data,
      // id: `${uid}`,
      createdAt: formatISO(now),
      updatedAt: formatISO(now),
      datetime: formatISO(new Date(data.datetime)),
      leader: displayName,
      leaderId: uid,
      views: 0,
    };
    const newContact = {
      mogakId: "",
      method: data.contact,
      detail: data.contactDetail,
    };
    delete newMogak["contactDetail"];

    const batch = firestore.batch();
    const newMogakDoc = firestore.collection("mogak").doc();
    batch.set(newMogakDoc, {
      id: newMogakDoc.id,
      ...newMogak,
    });
    const newContactDoc = firestore.collection("contact").doc();
    batch.set(newContactDoc, {
      ...newContact,
      mogakId: newMogakDoc.id,
    });

    const runBatch = async () => await batch.commit();
    runBatch().then(() => {
      navigate(`/mogak/${newMogakDoc.id}`);
    });
    // navigate(`/mogak/${docRef.id}`);

    // console.log(newMogak);
    // firestore
    //   .collection("mogak")
    //   .add(newMogak)
    //   .then((docRef) => {
    //     docRef.update({
    //       id: docRef.id,
    //     });
    //   });
  };

  return (
    <Container>
      <Contents>
        <h1>생성</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h2>제목(필수)</h2>
            <input
              {...register("title", { required: true, maxLength: 100 })}
              placeholder="일정 제목을 작성해주세요 (최대 100자)"
              maxLength={50}
            />
            {errors.title && <ErrorMsg>* 제목을 입력해주세요!</ErrorMsg>}
          </div>
          <div>
            <h2>날짜 및 시작시간</h2>
            <input
              {...register("datetime", { required: true })}
              type="datetime-local"
              placeholder="날짜"
            />
            {errors.datetime && (
              <ErrorMsg>* 날짜 및 시작 시간을 입력해주세요!</ErrorMsg>
            )}
          </div>
          <div>
            <h3>진행시간</h3> 약
            <input
              {...register("duration", { required: false })}
              type="number"
              placeholder="0"
            />{" "}
            시간
          </div>
          <div>
            <h2>내용 및 목적</h2>
            <input
              {...register("contents", { required: false })}
              placeholder="규칙, 공지 및 자세한 일정에 대해 자유롭게 작성해주세요 (1000자)"
              maxLength={2000}
            />
          </div>
          <div>
            <h3>장소</h3>
            <br />
            <label htmlFor="field-lo-offline">
              <input
                {...register("locationType")}
                type="radio"
                value="lo-offline"
                id="field-lo-offline"
              />
              오프라인
            </label>
            <br />
            <label htmlFor="field-lo-online">
              <input
                {...register("locationType")}
                type="radio"
                value="lo-online"
                id="field-lo-online"
              />
              온라인
            </label>
            <br />
            {locationType === "lo-offline" && (
              <>
                주소
                <select {...register("lo1")}>
                  <option key={"voidlo1"} value={""}>
                    {""}
                  </option>
                  {location1.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <select {...register("lo2")}>
                  <option key={"voidlo2"} value={""}>
                    { }
                  </option>
                  {location2[lo1] &&
                    location2[lo1].map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </>
            )}
            <br />
            <input
              {...register("locationDetail", {
                required: locationType === "lo-offline" ? false : true,
              })}
              placeholder={
                locationType === "lo-offline"
                  ? "세부주소 또는 지도 링크를 작성해주세요"
                  : "접속 방법 및 링크를 작성해주세요"
              }
            />
            {errors.locationDetail && (
              <ErrorMsg>
                * {locationType === "lo-offline" ? "장소를 " : "접속방법을 "}
                입력해주세요!
              </ErrorMsg>
            )}
          </div>
          <div>
            <h3>연락방법</h3>
            * 연락방법은 참가신청을 한 참가자에게만 공개됩니다.
            <br />* 참가자 개인정보 보호를 위하여, 이번 모각용 오픈채팅방을 새로
            만들어 공유하는 방법을 추천드립니다
            <br />
            <label htmlFor="field-phone">
              <input
                {...register("contact")}
                type="radio"
                value="phone"
                id="field-phone"
              />
              전화번호
            </label>
            <br />
            <label htmlFor="field-twitter">
              <input
                {...register("contact")}
                type="radio"
                value="twitter"
                id="field-twitter"
              />
              트위터 쪽지
            </label>
            <br />
            <label htmlFor="field-kakao-id">
              <input
                {...register("contact")}
                type="radio"
                value="kakao-id"
                id="field-kakao-id"
              />
              카카오톡 ID
            </label>
            <br />
            <label htmlFor="field-kakao-open">
              <input
                {...register("contact")}
                type="radio"
                value="kakao-open"
                id="field-kakao-open"
              />
              카카오톡 오픈채팅방 주소
            </label>
            <br />
            <label htmlFor="field-etc">
              <input
                {...register("contact")}
                type="radio"
                value="etc"
                id="field-etc"
              />
              기타
            </label>
            <br />
            <input
              {...register("contactDetail", { required: true })}
              placeholder={`${contact} 연락처 (최대 100자)`}
              maxLength={100}
            />
            {errors.contactDetail && (
              <ErrorMsg>* 연락방법을 입력해주세요! {contact}</ErrorMsg>
            )}
          </div>
          {/* <div>
          <h3>분위기</h3>
          <br />
          <label htmlFor="field-mogak">
            <input
              {...register("vibeType")}
              type="radio"
              value="mogak"
              id="field-mogak"
            />
            모각(모여서 각자): 각자 코딩하며 자유롭게 질문 답변 원하시는 분
          </label>
          <br />
          <label htmlFor="field-modae">
            <input
              {...register("vibeType")}
              type="radio"
              value="modae"
              id="field-modae"
            />
            모대(모여서 대화): 특정한 주제로 대화가 하고 싶은 분
          </label>
          <br />
          <label htmlFor="field-moham">
            <input
              {...register("vibeType")}
              type="radio"
              value="moham"
              id="field-moham"
            />
            모함(모여서 함께): 같이 책을 읽거나 스터디에 들어오고 싶은 분
          </label>
          <br />
          <label htmlFor="field-etc">
            <input
              {...register("vibeType")}
              type="radio"
              value="etc"
              id="field-etc"
            />
            기타: 직접작성
          </label>

          {vibeType === "etc" && (
            <>
              <br />
              <input
                {...register("vibeDetail", { required: true })}
                placeholder=""
              />
              {errors.vibeDetail && (
                <ErrorMsg>* 생각중인 분위기를 입력해주세요!</ErrorMsg>
              )}
            </>
          )}
        </div>
        <div>
          <h3>참여방법</h3>
          <br />
          <label htmlFor="field-free">
            <input
              {...register("applyType")}
              type="radio"
              value="free"
              id="field-free"
            />
            자유참여: 인원제한 없이 누구나 참여할 수 있습니다
          </label>
          <br />
          <label htmlFor="field-firstcome">
            <input
              {...register("applyType")}
              type="radio"
              value="firstcome"
              id="field-firstcome"
            />
            선착순: 최대 인원까지만 참여할 수 있습니다
          </label>
          <br />
          <label htmlFor="field-apply">
            <input
              {...register("applyType")}
              type="radio"
              value="apply"
              id="field-apply"
            />
            지원: 지원자를 받아 인원을 선별할 수 있습니다.
          </label>
        </div>
        {applyType !== "free" && (
          <div>
            <h3>최대 참여자 수</h3>
            <input
              {...register("peopleLimit", { required: true })}
              placeholder="0"
              type="number"
            />{" "}
            명
          </div>
        )}
        <div>
          <h3>회비</h3>
          <br />
          <label htmlFor="field-self">
            <input
              {...register("feeType")}
              type="radio"
              value="self"
              id="field-self"
            />
            기본: 자기가 먹을 식음료는 개인이 지불합니다(1인 1음료 등)
          </label>

          <br />
          <label htmlFor="field-fee-free">
            <input
              {...register("feeType")}
              type="radio"
              value="fee-free"
              id="field-fee-free"
            />
            무료: 일정 주가 모든 금액을 지불합니다
          </label>

          <br />
          <label htmlFor="field-add-dues">
            <input
              {...register("feeType")}
              type="radio"
              value="add-dues"
              id="field-add-dues"
            />
            회비: 일정 금액 이상의 회비를 내야 참여할 수 있습니다
          </label>

          {feeType === "add-dues" && (
            <>
              <br />
              <input
                {...register("feeAmount", { required: true })}
                placeholder="금액"
                type={"number"}
              />{" "}
              원
              {errors.feeAmount && <ErrorMsg>* 회비를 입력해주세요!</ErrorMsg>}
            </>
          )}
        </div>

        <div>
          <h3>공유형태</h3>
          <br />
          <label htmlFor="field-public">
            <input
              {...register("shareType")}
              type="radio"
              value="public"
              id="field-public"
            />
            전체: 모든 사람이 볼 수 있습니다
          </label>
          <br />
          <label htmlFor="field-link">
            <input
              {...register("shareType")}
              type="radio"
              value="link"
              id="field-link"
            />
            부분: 링크를 통해 들어온 사람만 볼 수 있습니다
          </label>
          <br />
          <label htmlFor="field-not-share">
            <input
              {...register("shareType")}
              type="radio"
              value="not-share"
              id="field-not-share"
            />
            비공개: 공유하지 않습니다 (작성자만 볼 수 있습니다)
          </label>
        </div> */}
          <MyButton type="submit" value={"생성하기"} />
          {/* <MyButton onClick={() => addMogak()}>생성하기</MyButton> */}
        </form>
      </Contents>
    </Container>
  );
};

export default CreateMogak;
