import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Contents } from "../MainLayout/Container";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ProfileImage } from "../User";

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  font-family: sans-serif;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 3;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Header = ({ auth, logout }) => {
  const { uid, displayName, imageUrl } = auth || {};
  return (
    <Navbar bg="light" expand="lg">
      <Container backgroundColor={"#b4b4b4"}>
        <Contents>
          <Navbar.Brand href="/">
            모각
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="me-auto">
              {auth ? (
                <>
                  <Row>
                    <ProfileImage url={imageUrl} sizeType="medium" />
                    <Link to={`/userInfo/`}>{displayName}</Link>
                  </Row>
                  <Link to="/create">새모임</Link>
                  <Link to={`/user/${uid}`}>참가일정</Link>
                  <Navbar.Text onClick={logout}>
                    로그아웃
                  </Navbar.Text>
                </>
              ) : (
                <Link to="/signin">로그인</Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Contents>
      </Container >
    </Navbar>
  );
};

export default Header;
