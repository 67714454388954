import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { rootReducer } from "./ducks/reducers";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import { composeWithDevTools } from "redux-devtools-extension"; // 리덕스 개발자 도구

const firebaseConfig = {
  apiKey: "AIzaSyDiTwDjpNnZn71nq6dFx3HpcbITzxDnheM",
  authDomain: "mogakco-fa385.firebaseapp.com",
  // databaseURL: "https://mogakco-fa385.firebaseio.com",
  projectId: "mogakco-fa385",
  storageBucket: "mogakco-fa385.appspot.com",
  messagingSenderId: "1074343829582",
  appId: "1:1074343829582:web:ea7074f9c8ff0ff150e31f",
  measurementId: "G-6R0LN0S3ZQ",
};

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

const initialState = {};
const store = createStore(rootReducer, initialState, composeWithDevTools());

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
