import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { useCallback } from "react";
import { ProfileImage } from "../User";
import { Container } from "../MainLayout/Container";
import { DDay } from "../DDay/DDay";
import { getDayTime } from "../../utils/Datetime";

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
	box-sizing: border-box;
	padding: 4px 8px;
	margin: 0 auto;
  color: black;
  text-decoration: none;
	font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
`;

const blank = keyframes`
	//단계 별로 변화를 주는 코드
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.3;
  }
  100%{
    opacity: 1;
  }
`;

const CardContainer = styled(Container)`
  display: inline-block;
  width: 300px;
  border-radius: 3px;
  padding: 0.5rem;
  background-color: ${props => props.backgroundColor || "fff"};
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  ${props => props.isMine && css`
    animation: ${blank} 1s infinite;
  `}
  
  @media screen and (max-width: 1023px) {
    width: 300px;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  flex: 3;
  display: flex;
  font-size: 1.1rem;
  font-weight: bold;
  align-items: center;
`;

const TimeBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: gray;
`;

const ContentBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: gray;
`;

const CardBottom = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  ${props => props.isMine && css`
    font-weight: bold;
  `};
`;

const Location = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const MogakCard = ({
  id,
  title,
  contents,
  datetime,
  locationType,
  userImg,
  userName,
  showTime,
  isMine = false,
  backgroundColor }) => {

  const diffDay = (masTime) => {
    const todayTime = new Date();
    const diff = masTime - todayTime;
    const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
    return diffDay + 1;
  }

  useCallback(diffDay, []);

  const dt = new Date(datetime)
  const dday = diffDay(dt);
  const isOutdated = (dday < 0);
  const { date, time } = getDayTime(datetime)

  return (
    <CardContainer
      key={id}
      backgroundColor={backgroundColor}
      isMine={isMine && !isOutdated}
    >
      <StyledLink to={`/mogak/${id}`} target="_blank" rel="noopener noreferrer">
        <TitleBox>
          <Title> {title} </Title>
          <DDay datetime={datetime} />
        </TitleBox>
        {showTime && (
          <TimeBox>
            <div>
              {date}
            </div>
            <div>
              {time}
            </div>
          </TimeBox>
        )}
        <ContentBox>
          {contents}
        </ContentBox>
        <CardBottom>

          <Location>
            {locationType}
          </Location>
          <UserBox
            isMine={isMine}
          >
            <ProfileImage url={userImg} sizeType="custom" customSize={18} />
            <span>{userName}</span>
          </UserBox>
        </CardBottom>
      </StyledLink>
    </CardContainer>
  );
};

export { MogakCard };

MogakCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  datetime: PropTypes.string,
  contents: PropTypes.string,
  locationType: PropTypes.string,
  views: PropTypes.number,
  backgroundColor: PropTypes.string,
};

MogakCard.defaultProps = {
  id: "1UoEc3Yga2oIavqYfa58",
  title: "햄스터 모임",
  datetime: "2022-09-23T01:44:00+09:00",
  contents: "햄스터를 봅니다.",
  locationType: "집",
  views: 1,
  backgroundColor: null,
};
