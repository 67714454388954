const location1 = [
  "서울특별시",
  "부산광역시",
  "대구광역시",
  "인천광역시",
  "광주광역시",
  "대전광역시",
  "울산광역시",
  "세종특별자치시",
  "경기도",
  "강원도",
  "충청북도",
  "충청남도",
  "경상북도",
  "경상남도",
  "전라북도",
  "전라남도",
  "제주특별자치도",
];

const location2 = {
  서울특별시: [
    "강남구",
    "강동구",
    "강북구",
    "강서구",
    "관악구",
    "광진구",
    "구로구",
    "금천구",
    "노원구",
    "도봉구",
    "동대문구",
    "동작구",
    "마포구",
    "서대문구",
    "서초구",
    "성동구",
    "성북구",
    "송파구",
    "양천구",
    "영등포구",
    "용산구",
    "은평구",
    "종로구",
    "중구",
    "중랑구",
  ],
  부산광역시: [
    "중구",
    "서구",
    "동구",
    "영도구",
    "부산진구",
    "동래구",
    "남구",
    "북구",
    "강서구",
    "해운대구",
    "사하구",
    "금정구",
    "연제구",
    "수영구",
    "사상구",
    "기장군",
  ],
  인천광역시: [
    "중구",
    "동구",
    "남구",
    "연수구",
    "남동구",
    "부평구",
    "계양구",
    "서구",
    "강화군",
    "옹진군",
  ],
  대구광역시: [
    "중구",
    "동구",
    "서구",
    "남구",
    "북구",
    "수성구",
    "달서구",
    "달성군",
  ],
  광주광역시: ["동구", "서구", "남구", "북구", "광산구"],
  대전광역시: ["동구", "중구", "서구", "유성구", "대덕구"],
  울산광역시: ["중구", "남구", "동구", "북구", "울주군"],
  세종특별자치시: [],

  경기도: [
    "가평군",
    "고양시",
    "과천시",
    "광명시",
    "광주시",
    "구리시",
    "군포시",
    "김포시",
    "남양주시",
    "동두천시",
    "부천시",
    "성남시",
    "수원시",
    "시흥시",
    "안산시",
    "안성시",
    "안양시",
    "양주시",
    "양평군",
    "여주시",
    "연천군",
    "오산시",
    "용인시",
    "의왕시",
    "의정부시",
    "이천시",
    "파주시",
    "평택시",
    "포천시",
    "하남시",
    "화성시",
  ],
  강원도: [
    "원주시",
    "춘천시",
    "강릉시",
    "동해시",
    "속초시",
    "삼척시",
    "홍천군",
    "태백시",
    "철원군",
    "횡성군",
    "평창군",
    "영월군",
    "정선군",
    "인제군",
    "고성군",
    "양양군",
    "화천군",
    "양구군",
  ],
  충청북도: [
    "청주시",
    "충주시",
    "제천시",
    "보은군",
    "옥천군",
    "영동군",
    "증평군",
    "진천군",
    "괴산군",
    "음성군",
    "단양군",
  ],
  충청남도: [
    "천안시",
    "공주시",
    "보령시",
    "아산시",
    "서산시",
    "논산시",
    "계룡시",
    "당진시",
    "금산군",
    "부여군",
    "서천군",
    "청양군",
    "홍성군",
    "예산군",
    "태안군",
  ],
  경상북도: [
    "포항시",
    "경주시",
    "김천시",
    "안동시",
    "구미시",
    "영주시",
    "영천시",
    "상주시",
    "문경시",
    "경산시",
    "군위군",
    "의성군",
    "청송군",
    "영양군",
    "영덕군",
    "청도군",
    "고령군",
    "성주군",
    "칠곡군",
    "예천군",
    "봉화군",
    "울진군",
    "울릉군",
  ],
  경상남도: [
    "창원시",
    "김해시",
    "진주시",
    "양산시",
    "거제시",
    "통영시",
    "사천시",
    "밀양시",
    "함안군",
    "거창군",
    "창녕군",
    "고성군",
    "하동군",
    "합천군",
    "남해군",
    "함양군",
    "산청군",
    "의령군",
  ],
  전라북도: [
    "전주시",
    "익산시",
    "군산시",
    "정읍시",
    "완주군",
    "김제시",
    "남원시",
    "고창군",
    "부안군",
    "임실군",
    "순창군",
    "진안군",
    "장수군",
    "무주군",
  ],
  전라남도: [
    "여수시",
    "순천시",
    "목포시",
    "광양시",
    "나주시",
    "무안군",
    "해남군",
    "고흥군",
    "화순군",
    "영암군",
    "영광군",
    "완도군",
    "담양군",
    "장성군",
    "보성군",
    "신안군",
    "장흥군",
    "강진군",
    "함평군",
    "진도군",
    "곡성군",
    "구례군",
  ],
  제주특별자치도: ["제주시", "서귀포시"],
};

const getLocation1ValueLable = () => {
  /**
    return : [
      { value: "서울특별시", label: "서울특별시" }, ...
    ]
  */
  return location1.map((key) => ({ value: key, label: key }));
}

const getLocation2ValueLable = (location1) => {
  /**
    location1 : "서울특별시"
    return : [
      { value: "강남구", label: "강남구" }, ...
    ]
  */
  const lo1 = getLocation1ValueLable().find((item) => item.value === location1);
  return location2[lo1.label].map((v) => ({ value: v, label: v }))
}

export { location1, location2, getLocation1ValueLable, getLocation2ValueLable };
