import React from "react";
import PropTypes from "prop-types";
import { Container, Contents } from "../MainLayout/Container";
import { FormField } from "../FormField/FormField";
import { useForm, FormProvider } from 'react-hook-form';
import { FormRadio } from "../FormRadio/FormRadio";
import { FormSelect } from "../FormSelect/FormSelect";
import { getLocation1ValueLable, getLocation2ValueLable } from "../../apis/koreaAddress";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { MogakItemKo } from "../../i18n/components";


const Forms = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;


const CreateForm = ({ }) => {
  // 아래 내용을 참고하여 완성하기
  // https://stackoverflow.com/questions/70416699/storybook-cannot-pass-register-prop-into-stories

  const onSubmit = async (data) => {
    console.log("onSubmit: data", data)
    // await signIn('credentials', {
    //   redirect: false,
    //   email: data.email,
    //   password: data.password
    // });

    // Router.push('/dashboard');
  };

  const getnow = () => {
    const now = new Date();
    let offset = now.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
    let dateOffset = new Date(now.getTime() - offset);
    return dateOffset.toISOString();
  };

  const methods = useForm({
    defaultValues: {
      title: "새 모각코",
      locationType: "offline",
      contactType: "kakaoOpen",
      datetime: getnow().slice(0, 16),
      lo1: "서울특별시",
      lo2: "강남구",
      shareType: "all",
      participantType: "free",
      // feeType: "self",
    },
  });
  const { handleSubmit, register, watch, formState: { errors }, reset } = methods;
  const title = watch("title");
  const contents = watch("contents");
  const locationType = watch("locationType");
  const participantType = watch("participantType");
  const contactType = watch("contactType");
  const lo1 = watch("lo1");
  const lo2 = getLocation2ValueLable(lo1);
  return (
    <Container>
      <Contents>
        <FormProvider {...methods}>
          <Forms
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormField
              {...register('title', {
                required: MogakItemKo.titleRequired,
                maxLength: {
                  value: 20,
                  message: MogakItemKo.titleErrorMsg
                }
              })}
              type="text"
              label={MogakItemKo.title}
              placeholder={MogakItemKo.titlePlaceholder}
              maxLength={20}
              errors={errors}
              required />
            <FormField
              {...register('contents', {
                required: MogakItemKo.contentsRequired,
                maxLength: {
                  value: 300,
                  message: MogakItemKo.contentsErrorMsg
                }
              })}
              type="textarea"
              label={MogakItemKo.contents}
              placeholder={MogakItemKo.contentsPlaceholder}
              errors={errors}
              maxLength={300}
              required />
            <FormField
              {...register('datetime', {
                required: MogakItemKo.datetimeRequired,
              })}
              type="datetime-local"
              label={MogakItemKo.datetime}
              errors={errors}
              required />
            <FormRadio
              {...register('locationType', {
                required: MogakItemKo.locationTypeRequired,
              })}
              name="locationType"
              type="radio"
              label={MogakItemKo.locationType}
              options={[
                { value: 'offline', label: MogakItemKo.offline },
                { value: 'online', label: MogakItemKo.online },
              ]}
              errors={errors}
              required />
            {locationType === "offline" && (
              <>
                <FormSelect
                  {...register('lo1', {
                    required: MogakItemKo.lo1Required,
                  })}
                  disabled={locationType === 'online'}
                  name="lo1"
                  label={MogakItemKo.lo1}
                  options={getLocation1ValueLable()}
                  errors={errors}
                  required />
                {lo1 && (
                  <FormSelect
                    {...register('lo2', {
                      required: MogakItemKo.lo2Required,
                    })}
                    disabled={locationType === 'online'}
                    name="lo2"
                    label={MogakItemKo.lo2}
                    options={lo2}
                    errors={errors}
                    required />
                )}
              </>
            )}
            <FormField
              {...register('locationDetail', {
                required: MogakItemKo.locationDetailRequired,
              })}
              type="text"
              label={MogakItemKo.locationDetail}
              placeholder={MogakItemKo.locationDetailPlaceholder}
              errors={errors}
              description={locationType === 'online' ?
                MogakItemKo.locationDetailOnlineDescription :
                MogakItemKo.locationDetailOfflineDescription}
              required />
            <FormRadio
              {...register('participantType', {
                required: MogakItemKo.participantTypeRequired,
              })}
              name="participantType"
              type="radio"
              label={MogakItemKo.participantType}
              options={[
                { value: 'free', label: MogakItemKo.free },
                { value: 'first', label: MogakItemKo.first },
                { value: 'contact', label: MogakItemKo.contact },
              ]}
              errors={errors}
              required />
            {participantType !== "free" && (
              <FormField
                {...register('participantCount', {
                  required: MogakItemKo.participantCountRequired,
                })}
                type="number"
                label={MogakItemKo.participantCount}
                placeholder={MogakItemKo.participantCountPlaceholder}
                errors={errors}
                description={locationType === 'online' ?
                  MogakItemKo.participantCountOnlineDescription :
                  MogakItemKo.participantCountOfflineDescription}
                required />
            )}
            <FormSelect
              {...register('contactType', {
                required: MogakItemKo.contactTypeRequired,
              })}
              name="contactType"
              label={MogakItemKo.contactType}
              options={[
                { value: 'none', label: MogakItemKo.none },
                { value: 'email', label: MogakItemKo.email },
                { value: 'phone', label: MogakItemKo.phone },
                { value: 'twitter', label: MogakItemKo.twitter },
                { value: 'kakaoId', label: MogakItemKo.kakaoId },
                { value: 'kakaoOpen', label: MogakItemKo.kakaoOpen },
                { value: 'other', label: MogakItemKo.other },
              ]}
              errors={errors}
              required={participantType !== "free"} />
            <FormField
              {...register('contactDetail', {
                required: MogakItemKo.contactDetailRequired,
              })}
              type="text"
              label={MogakItemKo.contactDetail}
              placeholder={MogakItemKo.contactDetailPlaceholder}
              errors={errors}
              register={register}
              disabled={contactType === 'none'}
              required={contactType !== "none"} />
            <FormRadio
              {...register('shareType', {
                required: MogakItemKo.shareTypeRequired,
              })}
              name="shareType"
              type="radio"
              label={MogakItemKo.shareType}
              options={[
                { value: 'all', label: MogakItemKo.all },
                { value: 'link', label: MogakItemKo.link },
              ]}
              errors={errors}
              required />
            <Button variant="success" type="submit" >작성하기</Button>
          </Forms>
        </FormProvider>
      </Contents>
    </Container >
  );
};

export { CreateForm };

CreateForm.propTypes = {
};

CreateForm.defaultProps = {
};